// assets
import { IconDeviceTv } from '@tabler/icons';

// constant
const icons = { IconDeviceTv };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'stream',
  title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Stream',
      type: 'item',
      url: '/stream/main',
      icon: icons.IconDeviceTv,
      breadcrumbs: false
    }
  ]
};

export default dashboard;
